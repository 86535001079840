.carousel-caption{
    top: 20% !important;
    left: 10% !important;
    text-align: left !important;
    width: 50%;
    display: flex !important;  
}

.carousel-caption h3{
    font-size: 60px !important;
    width: 70%;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    background: rgba(185, 173, 173, 0.41);
    padding: 20px;
    border-radius: 30px;
    text-transform: capitalize;
    color: #fff !important;
}

.carousel-caption p{
display: none !important;
}




