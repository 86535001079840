.box_sdw{
    box-shadow: 0px -13px 42px 24px rgba(233, 7, 7, 0.1);
  padding: 20px;
  border-radius: 25px;
  margin-bottom:20px;
  border: 1px solid #d71e1e;
  min-height: 220px;
  vertical-align: middle;
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: center;
}

.box_sdw h3{
  font-weight: 600;
}

.cnt_hder h2{
  font-size: 45px;
  font-weight: 600;
  color: #d71e1e;
  text-transform: uppercase;
}