.abt_head{
    font-size: 50px;
    font-weight: 600;
}
.abt_sec{
    background: url('../../assets/squarenetwork_about.png');
}
.abt_bann{
    color: #ffff;
    /* border-radius: 20px; */
    padding: 20px;
    margin: 20px 0;
    background-attachment: fixed;
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(45px);
    -webkit-backdrop-filter: blur(16.8px);
    /* border: 1px solid rgba(40, 87, 26, 1); */
}

.abt_sec img{
border-radius: 25px;
}

.our_vlu{
    text-align: center;
    font-size: 45px;
    font-weight: 600;
    margin-bottom: 40px;
    color: #c12329;
}

.abt_our_each{
    text-align: center;
  padding: 20px;
  border-radius: 20px;
  background: #eee7e7;
  min-height: 250px;
  transition: 0.3s;
  margin-bottom: 15px;
}

.abt_our_each:hover{
transform: scale(1.1);
}

.abt_our_each span{
    font-size: 18px;
}
.abt_our_each h3{
    font-size: 30px;
}

.abt_our_each svg{
    margin-bottom: 15px;
    width: 80px;
    height: 80px;
}


.abt_bann p{
    font-size: 18px;
}