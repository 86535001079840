ul {
    margin: 0px;
    padding: 0px;
}
.footer-section {
  background: #151414;
  position: relative;
  padding: 20px;
}
.footer-cta {
  border-bottom: 1px solid #373636;
}
.single-cta i {
  color: #ff5e14;
  font-size: 30px;
  float: left;
  margin-top: 8px;
}
.cta-text {
  padding-left: 15px;
  display: inline-block;
}
.cta-text h4 {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 2px;
}
.cta-text span {
  color: #fff;
  font-size: 15px;
}
.footer-content {
  position: relative;
  z-index: 2;
  background: #fff;
padding: 20px;
border-radius: 15px;
margin-bottom: 30px;
}
.footer-pattern img {
  position: absolute;
  top: 0;
  left: 0;
  height: 330px;
  background-size: cover;
  background-position: 100% 100%;
}

.single-cta svg {
width: 50px;
}

.single-cta {
  display: flex;
}
.footer-logo {
  margin-bottom: 30px;
}
/* .footer-logo img {
    max-width: 200px;
} */
.footer-text p {
  margin-bottom: 14px;
  font-size: 14px;
      color: #000;
  line-height: 28px;
}
.footer-social-icon span {
  color: #fff;
  display: block;
  font-size: 20px;
  font-weight: 700;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 20px;
}
.footer-social-icon a {
  color: #fff;
  font-size: 16px;
  margin-right: 15px;
}
.footer-social-icon i {
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 38px;
  border-radius: 50%;
}
.facebook-bg{
  background: #3B5998;
}
.twitter-bg{
  background: #55ACEE;
}
.google-bg{
  background: #DD4B39;
}
.footer-widget-heading h3 {
  color: #f00;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 40px;
  position: relative;
}
.footer-widget-heading h3::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -15px;
  height: 2px;
  width: 50px;
  background: #ff5e14;
}

.footer-widget ul li {
  display: inline-block;
  /* float: left; */
  width: 50%;
  margin-bottom: 12px;
}

.footer-widget ul li a:hover{
  color: #fc1108;
}
.footer-widget ul li a {
  color: #000;
  text-transform: capitalize;
}
.subscribe-form {
  position: relative;
  overflow: hidden;
}
.subscribe-form input {
  width: 100%;
  padding: 14px 28px;
  background: #2E2E2E;
  border: 1px solid #2E2E2E;
  color: #fff;
}
.subscribe-form button {
    position: absolute;
    right: 0;
    background: #ff5e14;
    padding: 13px 20px;
    border: 1px solid #ff5e14;
    top: 0;
}
.subscribe-form button i {
  color: #fff;
  font-size: 22px;
  transform: rotate(-6deg);
}
.copyright-area{
  background: #202020;
  padding: 25px 0;
}
.copyright-text p {
  margin: 0;
  font-size: 14px;
  color: #fff;
}
.copyright-text p a{
  color: #ff5e14;
}
.footer-menu li {
  display: inline-block;
  margin-left: 20px;
}
.footer-menu li:hover a{
  color: #ff5e14;
}
.footer-menu li a {
  font-size: 14px;
  color: #fff;
}

.fot_icon div svg{
  width: 100%;
height: 100%;
}

.fot_icon{
  text-align: center;
}
.single-cta{
  margin-bottom: 20px;
}

@media (min-width:0px) and (max-width: 360px) {
  .fot_icon div svg{
    width: 50%;
  height: 50%;
  }
}

.red_net{
  background-image: url('../assets/red_net.png');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  /* opacity: 0.3; */
  /* transition: background 0.3s, border-radius 0.3s, opacity 0.3s; */
}

.red_nett{
  background-image: url('../assets/cable_bg.png');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  height: 50vh;
}


/* whatsapp */
.pulse i {
	position: relative;
	z-index: 20;
	top: 16px;
	font-size: 30px;
  }
  .pulse {
	position: fixed;
	cursor: pointer;
	display: block;
	width: 60px !important;
	height: 60px;
	bottom: 40px;
	right: 40px;
	background-color: rgba(0, 200, 0, 1);
	color: white;
	border-radius: 50px;
	text-align: center;
	transition-duration: 0.5s;
	animation: pulse-btn 3s infinite;
	z-index: 9000;
  }
  
  .pulse:hover {
	background-color: rgba(0, 150, 0, 1);
	color: white;
  }
  
  .my-float {
	margin-top: 15px;
  }
  
  @keyframes pulse-btn {
	0% {
	  -webkit-box-shadow: 0 0 0 0 rgba(0, 200, 0, 1);
	}
	70% {
	  -webkit-box-shadow: 0 0 0 20px rgba(0, 200, 0, 0);
	}
	100% {
	  -webkit-box-shadow: 0 0 0 0 rgba(0, 200, 0, 0);
	}
  }
  
  .pulse a {
	color: #fff;
  }
  