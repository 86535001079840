.navbar {
  background-color: #fff;
  color: #000;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  position: fixed;
  padding: 30px !important;
  top: 0;
  z-index: 20;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 0px 10px;
  flex-wrap: nowrap !important;
}

.nav-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* height: 80px; */
  max-width: 1500px;
  position: relative;
}

.nav-logo {
  color: #000;
  align-items: center;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  flex-grow: 1;

  display: flex;
  align-items: center;
  justify-items: center;
}

.square_logo{
height: 60px;
width: 300px;
}
.mobile_logo{
  display: none !important;
}
.nav-logo .icon {
  display: inline-block;
  width: 3rem;
  height: 3rem;
  margin-left: 16px;
}

.nav-menu {
  display: flex;
  list-style: none;
  text-align: center;
  margin-right: 2rem;
  margin-bottom: 0 !important;
}

.nav-links {
  color: #000;
  text-decoration: none;
  padding: 8px 8px;
  height: 100%;
  border-bottom: 3px solid transparent;
}
/* .fa-code {
  margin-left: 1rem;
} */

.nav-item {
  line-height: 40px;
  margin-right: 1rem;
}

.nav-item:after {
  content: "";
  display: block;
  height: 3px;
  width: 0;
  background: transparent;
  transition: width 0.7s ease, background-color 0.5s ease;
}

.nav-item:hover:after {
  width: 100%;
  background: #ffdd40;
}
@media screen and (min-width: 360px) {
  .nav-item .active{
color: #fff;
  }

}
.nav-item .active {
  color: #000;
  /* border: 1px solid #ffdd40; */
}

.nav-icon {
  display: none;
  color: linear-gradient(90deg, rgba(54,68,235,1) 0%, rgba(90,83,197,1) 43%, rgba(142,100,146,1) 100%);
}

.nav-icon .icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
}


@media screen and (max-width: 960px) {
  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-top: 1pxsolid #fff;
    position: absolute;
    top: 80px;
    left: -110%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: linear-gradient(90deg, rgb(63, 27, 27) 0%, rgb(238, 35, 37) 43%, rgb(240, 67, 69) 100%);;
    left: 0px;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
    border-radius: 15px;
  }
  .nav-item .active {
    color: #000;
    border: none;
  }
  .nav-links {
    /* padding: 1.5rem; */
    width: 100%;
    display: table;
  }

  .nav-logo .icon {
    width: 2rem;
    height: 2rem;
  }

  .nav-icon {
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(-100%, -50%);
    width: 2rem;
    height: 2rem;
    cursor: pointer;
    color: #9036e3;
  }
}

@media (min-width:0px) and (max-width: 360px) {
  .mobile_logo{
    display: block !important;
    width: 70px;
    height: 70px;
  }
  .square_logo{
display: none !important;
  }
  
  .navbar{
    padding: 0 !important;
  }
}


.counter
{
    /* background-color: #eaecf0; */
    background-image: url('../assets//world_map.svg');
    background-color: #5b5959  !important;
    text-align: center;
    background-attachment: fixed;
}
.employees,.customer,.design,.order
{
    margin-top: 70px;
    margin-bottom: 70px;
}
.counter-count
{
    font-size: 18px;
    background-color: #00b3e7;
    border-radius: 50%;
    position: relative;
    color: #ffffff;
    text-align: center;
    line-height: 92px;
    width: 92px;
    height: 92px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    display: inline-block;
}

.employee-p,.customer-p,.order-p,.design-p
{
    font-size: 24px;
    color: #fff;
    line-height: 34px;
    font-size: 30px;
    font-weight: bold;
}


.counter-countt{
  font-size: 18px;
  background-color: #00b3e7;
  border-radius: 50%;
  position: relative;
  color: #ffffff;
  text-align: center;
  line-height: 92px;
  width: 92px;
  height: 92px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  display: inline-block;
}



	



ul.tabs{
  margin: 0px;
  padding: 0px;
  list-style: none;
}
ul.tabs li{
  background: none;
  color: #222;
  display: inline-block;
  padding: 10px 15px;
  cursor: pointer;
  text-align: center;
  margin: 0 30px 30px 0;
}

ul.tabs li.current{
  background: #ff00001c;
  color: #222;
  border-radius: 25px;
}

.tab-content{
  display: none;
  background: #ff00001c;
  padding: 30px;
  border-radius: 30px;
  margin-bottom: 30px;
}

.tab-content h3{
font-size: 40px;
color: #c12329;
}

.tab-content.current{
  display: inherit;
}

.tabs li  div img{
  width: 100px;
  height: 100px;
}

.tabs{
  text-align: center;
}

.tab-content ul li{
line-height: 30px;
font-size: 20px;
}





/* animation */



.slideshow {
  position: relative;
  /* width: 300px; */
  height: 70vh;
  transform-style: preserve-3d;
  perspective: 1000px;
  /*   overflow: hidden; */
}

.slideshow .slide {
  position: absolute;
  inset: 0;
  display: flex;
  align-items: start;
  justify-content: start;
  transform: rotateY(270deg);
  transform-style: preserve-3d;
  animation: animate 20s linear infinite;
  animation-delay: calc(var(--i) * 2s);
}

@keyframes animate {
  0% {
    transform: rotateY(270deg);
  }

  10%,
  100% {
    transform: rotateY(90deg);
  }
}

.slideshow .slide img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;
}

.slideshow .slide h2 {
  position: relative;
  z-index: 1000;
  color: #ffffff;
  font-size: 10em;
  transform: rotateY(180deg) translateY(100px) translateZ(50px);
  transform-style: preserve-3d;
  backface-visibility: hidden;
  font-weight: 900;
}

.slideshow .slide h2 span {
  position: absolute;
  top: 80%;
  left: 0;
  font-size: 0.5em;
  font-weight: 500;
  transform: translateZ(50px);
  backface-visibility: hidden;
}

.rotation_sec
{
  background-color: #000;
  border-radius: 30px;
 }




 .bdt-timeline-container {
  display: flow-root;
  box-sizing: content-box;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  overflow: hidden;
  margin-top: 50px;
}

.upk-salf-slider-wrapper {
  display: flex;
  flex-direction: row;
  background: #fff;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
  height: 400px;
  padding: 30px;
  position: relative;
}
.upk-salf-slider-wrapper .upk-salf-item {
  position: relative;
  display: flex;
  flex-direction: row-reverse;
}
.upk-salf-slider-wrapper .upk-salf-item.swiper-slide-active .upk-salf-title,
.upk-salf-slider-wrapper .upk-salf-item.swiper-slide-active .upk-salf-desc,
.upk-salf-slider-wrapper .upk-salf-item.swiper-slide-active .upk-salf-button {
  opacity: 1;
}
.upk-salf-slider-wrapper .upk-salf-item .upk-salf-image-wrap {
  height: 100%;
  width: 100%;
}
.upk-salf-slider-wrapper .upk-salf-item .upk-xanc-img {
  display: block;
  width: 100%;
  height: 800px;
  -o-object-fit: cover;
     object-fit: cover;
}
.upk-salf-slider-wrapper .upk-salf-item .upk-salf-content-wrap {
  position: absolute;
  left: 0;
  bottom: 0;
  top: unset;
  max-width: 460px;
  overflow: hidden;
  background: #ffffff8a;
  -webkit-backdrop-filter: blur(15px);
          backdrop-filter: blur(15px);
  transition: -webkit-backdrop-filter 0.9s;
  transition: backdrop-filter 0.9s;
  transition: backdrop-filter 0.9s, -webkit-backdrop-filter 0.9s;
  padding: 20px;
  padding-left: 20px;
}
.upk-salf-slider-wrapper .upk-salf-item .upk-salf-title {
  font-size: 23px;
  font-weight: 700;
  line-height: 1.2;
  color: #2b2d42;
  text-transform: capitalize;
  margin-bottom: 20px;
  opacity: 0;
}
.upk-salf-slider-wrapper .upk-salf-item .upk-salf-desc {
  color: #656565;
  font-size: 14px;
  line-height: 1.6;
  text-transform: capitalize;
  margin-bottom: 20px;
  opacity: 0;
}
.upk-salf-slider-wrapper .upk-salf-item .upk-salf-button {
  opacity: 0;
}
.upk-salf-slider-wrapper .upk-salf-item .upk-salf-button .link {
  color: #2b2d42;
  cursor: pointer;
  font-weight: 500;
  text-decoration: none;
  text-transform: capitalize;
  font-size: 14px;
  transition: all 0.3s ease;
}
.upk-salf-slider-wrapper .upk-salf-item .upk-salf-button .link:hover {
  color: #ff215a;
}
.upk-salf-slider-wrapper .upk-salf-item .upk-salf-button .link--arrowed {
  display: inline-block;
}
.upk-salf-slider-wrapper .upk-salf-item .upk-salf-button .link--arrowed .arrow-icon {
  position: relative;
  top: 0;
  transition: transform 0.3s ease;
  vertical-align: middle;
}
.upk-salf-slider-wrapper .upk-salf-item .upk-salf-button .link--arrowed .arrow-icon--circle {
  transition: stroke-dashoffset 0.3s ease;
  stroke-dasharray: 95;
  stroke-dashoffset: 95;
}
.upk-salf-slider-wrapper .upk-salf-item .upk-salf-button .link--arrowed g {
  stroke: currentColor;
  color: #2b2d42;
}
.upk-salf-slider-wrapper .upk-salf-item .upk-salf-button .link--arrowed:hover .arrow-icon {
  transform: translate3d(5px, 0, 0);
}
.upk-salf-slider-wrapper .upk-salf-item .upk-salf-button .link--arrowed:hover .arrow-icon--circle {
  stroke-dashoffset: 0;
}
.upk-salf-slider-wrapper .upk-salf-item .upk-salf-button .link--arrowed:hover g {
  color: #ff215a;
}
.upk-salf-slider-wrapper .upk-page-scroll {
  position: absolute;
  bottom: 8%;
  right: 5%;
  transform: rotate(90deg);
  z-index: 1;
  display: none;
}
.upk-salf-slider-wrapper .upk-page-scroll .arrow-up {
  height: 70px;
  width: 70px;
  display: block;
  background: #ffffffc9;
  -webkit-backdrop-filter: blur(18px);
          backdrop-filter: blur(18px);
  position: relative;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.25, 1.7, 0.35, 1.5);
  transform: rotate(-90deg);
  overflow: hidden;
}
.upk-salf-slider-wrapper .upk-page-scroll .arrow-slide {
  left: 0;
  top: -100%;
  width: 100%;
  height: 100%;
  background: #ff215a;
  position: absolute;
  display: block;
  z-index: 0;
}
.upk-salf-slider-wrapper .upk-page-scroll .long-arrow-left {
  display: block;
  margin: 30px auto;
  width: 16px;
  height: 16px;
  border-top: 2px solid #2b2d42;
  border-left: 2px solid #2b2d42;
}
.upk-salf-slider-wrapper .upk-page-scroll .long-arrow-left {
  transform: rotate(-135deg);
}
.upk-salf-slider-wrapper .upk-page-scroll .long-arrow-left::after {
  content: "";
  display: block;
  width: 2px;
  height: 25px;
  background-color: #2b2d42;
  transform: rotate(-45deg) translate(8px, 3px);
  left: 0;
  top: 0;
}
.upk-salf-slider-wrapper .upk-page-scroll .arrow-up:hover {
  transition: all 0.1s;
}
.upk-salf-slider-wrapper .upk-page-scroll .arrow-up:hover .left-arm:after {
  transform: rotate(-10deg);
}
.upk-salf-slider-wrapper .upk-page-scroll .arrow-up:hover .right-arm:after {
  transform: rotate(10deg);
}
.upk-salf-slider-wrapper .upk-page-scroll .arrow-up:hover .arrow-slide {
  transition: all 0.5s ease-in-out;
  transform: translateY(200%);
}
.upk-salf-slider-wrapper .upk-salf-nav-pag-wrap {
  position: absolute;
  top: 0;
  height: 100%;
  right: 0;
}
.upk-salf-slider-wrapper .upk-salf-navigation {
  margin-top: 40px;
  margin-right: 2px;
}
.upk-salf-slider-wrapper .upk-salf-navigation .link--arrowed {
  display: inline-block;
}
.upk-salf-slider-wrapper .upk-salf-navigation .link--arrowed .arrow-icon {
  position: relative;
  top: 0;
  transition: transform 0.3s ease;
  vertical-align: middle;
}
.upk-salf-slider-wrapper .upk-salf-navigation .link--arrowed .arrow-icon--circle {
  transition: stroke-dashoffset 0.3s ease;
  stroke-dasharray: 95;
  stroke-dashoffset: 95;
}
.upk-salf-slider-wrapper .upk-salf-navigation .link--arrowed g {
  stroke: currentColor;
  color: #2b2d42;
}
.upk-salf-slider-wrapper .upk-salf-navigation .link--arrowed:hover .arrow-icon {
  transform: translate3d(5px, 0, 0);
}
.upk-salf-slider-wrapper .upk-salf-navigation .link--arrowed:hover .arrow-icon--circle {
  stroke-dashoffset: 0;
}
.upk-salf-slider-wrapper .upk-salf-navigation .link--arrowed:hover g {
  color: #ff215a;
}
.upk-salf-slider-wrapper .upk-salf-navigation .upk-button-next {
  margin-top: 15px;
  transform: rotate(90deg);
}
.upk-salf-slider-wrapper .upk-salf-navigation .upk-button-prev {
  transform: rotate(-90deg);
}
.upk-salf-slider-wrapper .upk-salf-pagi-wrap {
  position: absolute;
  top: 43%;
  right: 0;
  margin-right: 22px;
}
@-webkit-keyframes progress {
  0% {
    stroke-dashoffset: 75;
    opacity: 1;
  }
  95% {
    stroke-dashoffset: 0;
    opacity: 1;
  }
  100% {
    opacity: 0;
    stroke-dashoffset: 0;
  }
}
@keyframes progress {
  0% {
    stroke-dashoffset: 75;
    opacity: 1;
  }
  95% {
    stroke-dashoffset: 0;
    opacity: 1;
  }
  100% {
    opacity: 0;
    stroke-dashoffset: 0;
  }
}
.upk-salf-slider-wrapper .upk-salf-pagi-wrap .swiper-pagination-bullet {
  background-color: transparent;
  opacity: 0.8;
}
.upk-salf-slider-wrapper .upk-salf-pagi-wrap .swiper-pagination-bullet--svg-animation {
  width: 20px;
  height: 20px;
  margin: 6px -7px;
  display: inline-block;
}
.upk-salf-slider-wrapper .upk-salf-pagi-wrap .swiper-pagination-bullet--svg-animation svg {
  transform: rotate(-90deg);
}
.upk-salf-slider-wrapper .upk-salf-pagi-wrap .swiper-pagination-bullet--svg-animation .svg__circle-inner {
  stroke: #2b2d42;
  fill: transparent;
  transition: all 0.3s ease;
}
.upk-salf-slider-wrapper .upk-salf-pagi-wrap .swiper-pagination-bullet-active .svg__circle {
  stroke: #ff215a;
  stroke-dasharray: 75;
  stroke-dashoffset: 0;
  -webkit-animation: progress 4s ease-in-out 1 forwards;
  animation: progress 4s ease-in-out 1 forwards;
}
.upk-salf-slider-wrapper .upk-salf-pagi-wrap .swiper-pagination-bullet-active .svg__circle-inner {
  fill: #2b2d42;
  stroke: #ff215a;
}

@media (min-width: 768px) {
  .upk-salf-slider-wrapper {
    height: 550px;
    padding: 60px;
  }
  .upk-salf-slider-wrapper .upk-page-scroll {
    bottom: 4%;
    display: inherit;
  }
  .upk-salf-slider-wrapper .upk-salf-item .upk-salf-title {
    font-size: 40px;
  }
  .upk-salf-slider-wrapper .upk-salf-item .upk-salf-content-wrap {
    max-width: 400px;
    padding: 40px;
    padding-left: 0;
    top: 50%;
    transform: translateY(-50%);
    bottom: unset;
  }
  .upk-salf-slider-wrapper .upk-salf-item .upk-salf-image-wrap {
    width: 80%;
  }
  .upk-salf-slider-wrapper .upk-salf-navigation {
    margin-top: 60px;
    margin-right: 20px;
  }
  .upk-salf-slider-wrapper .upk-salf-pagi-wrap {
    margin-right: 47px;
  }
  .upk-salf-slider-wrapper .upk-salf-pagi-wrap .swiper-pagination-bullet--svg-animation {
    margin: 6px 0;
  }
}
@media (min-width: 1024px) {
  .upk-salf-slider-wrapper {
    height: 650px;
    padding: 70px;
  }
  .upk-salf-slider-wrapper .upk-page-scroll {
    bottom: 9%;
  }
  .upk-salf-slider-wrapper .upk-salf-item .upk-salf-title {
    font-size: 50px;
  }
  .upk-salf-slider-wrapper .upk-salf-item .upk-salf-content-wrap {
    max-width: 460px;
    padding: 50px;
    padding-left: 0;
  }
  .upk-salf-slider-wrapper .upk-salf-item .upk-salf-desc {
    font-size: 16px;
  }
  .upk-salf-slider-wrapper .upk-salf-item .upk-salf-button .link {
    font-size: 16px;
  }
  .upk-salf-slider-wrapper .upk-salf-item .upk-salf-image-wrap {
    width: 70%;
  }
  .upk-salf-slider-wrapper .upk-salf-navigation {
    margin-top: 85px;
    margin-right: 30px;
  }
  .upk-salf-slider-wrapper .upk-salf-pagi-wrap {
    margin-right: 53px;
  }
  .upk-salf-slider-wrapper .upk-salf-pagi-wrap .swiper-pagination-bullet--svg-animation {
    margin: 6px -5px;
  }
}
@media (min-width: 1440px) {
  .upk-salf-slider-wrapper {
    height: 700px;
    padding: 80px;
  }
  .upk-salf-slider-wrapper .upk-salf-item .upk-salf-title {
    font-size: 55px;
  }
}

/* .button {
  background: #f00;
  padding: 18px 20px;
  text-transform: uppercase;
  margin-top: 50px;
  margin-bottom: 50px;
  display: inline-block;
  text-decoration: none;
  font-weight: 700;
  font-size: 15px;
  color: #fff;
} */


