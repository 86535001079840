a.button {
  display:inline-block;
  width:100%;
  height:50px;
  background-color:#9e9e9e;
  color:#fff;
  line-height:50px;
  text-align:center;
  text-decoration: none;
  margin-bottom: 8px;
  font-size: 25px;
  font-weight: 600;
}

/* a.active {
  background-color:red;
} */

.content {
  margin-top:30px;
}

/* div[class*="content-"] { 
  display:none;
} */

div.active { 
  display:block;
}

.justify_content_center{
  justify-content: center !important;
  display: flex;
  flex-direction: column;
}