.App{
  text-align: center;
}
body {
  font-family: "Space Grotesk";
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  /* overflow-x: hidden; */
}
img{
  width: 100%;
}

section{ padding: 50px 0;}
.heading_top{overflow-y: hidden; font-size: 40px; font-weight: bold; color: #c12329;}
.heading_top_p{font-size: 20px; color: #000000;padding: 16px 0;
}
.hme_box{
  padding: 20px;
  background: #efefef;
  margin-bottom: 20px;
  transition: 0.3s;
}

.hme_box:hover{
background-color: #f8acac;
transform: scale(1.1);
border-radius: 20px;
}

.hme_box h5{
  font-size: 25px;
  font-weight: 600;
}
.hme_box p{
  font-size: 18px;
}
.cntr_cntr_hme{
  display: flex;
  justify-content: center;
 
  flex-direction: column;
  align-content: center;
}

.btnn:hover{color: #FFFFFF; border: 1px solid #8e6492; border-radius:8px !important; padding: 8px 16px; background: rgb(54,68,235);
  background: linear-gradient(90deg, rgb(249, 6, 6) 0%, rgb(255, 6, 6) 43%, rgb(227, 7, 7) 100%);}

.btnn{float: left; text-decoration: none; color: #FFFFFF; border: 1px solid #e6f600; border-radius:8px !important; padding: 8px 16px; background: rgb(142,100,146);  background: linear-gradient(90deg, rgb(239, 36, 59) 0%, rgb(253, 4, 4) 43%, rgb(255, 2, 10) 100%);
margin-bottom: 20px;
}


.border_center{color: #FFFFFF; background: rgb(142,100,146);  background: linear-gradient(90deg, rgba(142,100,146,1) 0%, rgba(90,83,197,1) 43%, rgba(54,68,235,1) 100%); text-align: center; padding: 22px 0; font-size: 28px;}
.each_sec_part img{width: 147px;}
.each_sec_part {margin-bottom: 24px;width: 294px; padding: 22px;border-radius: 16px; text-align: center; box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;}
.text-center{text-align: center;}
.each_sec_part h4{ overflow-y: hidden; }
.felx_sec{ display: flex; justify-content: center; align-items: center;}
.partner_sec{  text-align: center; margin-top: 40px  !important;}
.partner_sec h4{ font-size: 20px; font-weight: 700 !important;line-height: 30px;}
.partner_sec p{ font-size: 16px; font-weight: 400;line-height: 24px;}
.each_sec_part p ul{ text-align: left !important;margin-top: 28px;}
.each_sec_part p li{ list-style-type:disc;}
.footer_labe { display: flex; justify-content: right;}
.footer_labe div input,textarea{box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;width: 300px; padding: 5px;border-radius: 5px; border:1px solid #4f4ed0;}
.ques_img {width: 50%;}
h4{overflow: hidden;}

.nav{
  width: 100%;
  height: 65px;
  position: fixed;
  line-height: 65px;
  text-align: center;
  background-color: rgba(0,0,255,0.8);
}

.nav div.logo{
  width: 180px;
  /* height: 10px; */
  position: absolute;
}

.nav div.logo a{
  text-decoration: none;
  color: #fff;
  font-size: 1.8em;
  text-transform: uppercase;
}

.nav div.logo a:hover {
  color: #c0c0c0;
}

.nav div.main_list{
  width: 600px;
  /* height: 65px; */
  float: right;
}

.nav div.main_list ul{
  width:100%;
  /* height: 65px; */
  display: flex;
  list-style: none;
}

.nav div.main_list ul li{
  width: 120px;
  height: 65px;
}

.nav div.main_list ul li a{
  text-decoration: none;
  color: #fff;
  line-height: 65px;
  text-transform: uppercase;
}

.nav div.main_list ul li a:hover{
  color: #c0c0c0;
}

.nav div.media_button {
  width: 40px;
  height: 40px;
  background-color: transparent;
  position: absolute;
  right: 15px;
  top: 12px;
  display: none;
}

.nav div.media_button button.main_media_button {
  width: 100%;
  height: 100%;
  background-color: transparent;;
  outline: 0;
  border: none;
  cursor: pointer;
}

.nav div.media_button button.main_media_button span{
  width: 98%;
  height: 1px;
  display: block;
  background-color: #fff;
  margin-top: 9px;
  margin-bottom: 10px;
}

.nav div.media_button button.main_media_button:hover span:nth-of-type(1){
  transform: rotateY(180deg);
  transition: all 0.5s;
  background-color: #c0c0c0;
}

.nav div.media_button button.main_media_button:hover span:nth-of-type(2){
  transform: rotateY(180deg);
  transition: all 0.4s;
  background-color: #c0c0c0;
}

.nav div.media_button button.main_media_button:hover span:nth-of-type(3){
  transform: rotateY(180deg);
  transition: all 0.3s;
  background-color: #c0c0c0;
}

.nav div.media_button button.active span:nth-of-type(1) {
  transform: rotate3d(0, 0, 1, 45deg);
  position: absolute;
  margin: 0;
}

.nav div.media_button button.active span:nth-of-type(2) {
  display: none;
}

.nav div.media_button button.active span:nth-of-type(3) {
  transform: rotate3d(0, 0, 1, -45deg);
  position: absolute;
  margin: 0;
}

.nav div.media_button button.active:hover span:nth-of-type(1) {
  transform: rotate3d(0, 0, 1, 20deg);
}

.nav div.media_button button.active:hover span:nth-of-type(3) {
  transform: rotate3d(0, 0, 1, -20deg);
}




.cards {
  --cards-text-color: white;
  --cards-closed-size: 4rem;
  --animation-speed-normal: .5s;
  --animation-speed-fast: .25s;
  
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}

.card {
  width: 100%;
  height: var(--cards-closed-size);
  overflow: hidden;
  border-radius: 1rem;
  position: relative;
  z-index: 1;
  transition: all var(--animation-speed-normal) ease-in-out;
  cursor: pointer;
}

.card[active] {
  height: 56vw;
}

.card__image {
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  filter: brightness(.675) saturate(75%);
  transition: filter var(--animation-speed-fast) ease-in-out;
}

.card:hover .card__image {
  filter: brightness(.875) saturate(100%);
}

.card__infos {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
  height: var(--cards-closed-size);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: .125rem;
  width: 100%;
  padding: .25rem 1rem;
}

.card__name {
  margin: 0;
  color: var(--cards-text-color);
  transform: translateY(.65rem);
  transition: all var(--animation-speed-normal) ease-in-out;
  font-size: 1.5rem;
}

.card[active] .card__name {
  transform: translateY(0);
}

.card__author {
  margin: 0;
  color: var(--cards-text-color);
  text-decoration: none;
  transform: translateY(.65rem);
  opacity: 0;
  transition:
    opacity var(--animation-speed-fast) ease-in-out,
    transform var(--animation-speed-normal) ease-in-out;
}

.card[active] .card__author {
  transform: translateY(0);
  opacity: 1;
}

.card[active] .card__author:hover {
  opacity: .75;
}

@media screen and (min-width: 640px) {
  .cards {
    max-width: 640px;
    margin: 0 auto;
    flex-direction: row;
  }

  .card {
    width: var(--cards-closed-size);
    height: 20rem;
    flex: 0 0 var(--cards-closed-size);
  }

  .card[active] {
    width: 100%;
    height: 20rem;
    flex: 0 0 calc(640px - 19rem);
  }
  
  .card__name {
    transform: rotate(-90deg) translate(1rem, -2.25rem);
  }
}


/* whatsapp  */

.pulse i {
	position: relative;
	z-index: 20;
	top: 16px;
	font-size: 30px;
  }
  .pulse {
	position: fixed;
	cursor: pointer;
	display: block;
	width: 60px !important;
	height: 60px;
	bottom: 40px;
	right: 40px;
	background-color: rgba(0, 200, 0, 1);
	color: white;
	border-radius: 50px;
	text-align: center;
	transition-duration: 0.5s;
	animation: pulse-btn 3s infinite;
	z-index: 9000;
  }
  
  .pulse:hover {
	background-color: rgba(0, 150, 0, 1);
	color: white;
  }
  
  .my-float {
	margin-top: 15px;
  }
  
  @keyframes pulse-btn {
	0% {
	  -webkit-box-shadow: 0 0 0 0 rgba(0, 200, 0, 1);
	}
	70% {
	  -webkit-box-shadow: 0 0 0 20px rgba(0, 200, 0, 0);
	}
	100% {
	  -webkit-box-shadow: 0 0 0 0 rgba(0, 200, 0, 0);
	}
  }
  
  .pulse a {
	color: #fff;
  }
  

  /*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 versions
*/



*,
::before,
::after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}
@media screen and (min-width: 768px) {
  html {
    font-size: 100%;
  }
}
@media screen and (min-width: 1920px) {
  html {
    font-size: 125%;
  }
}
@media screen and (min-width: 2560px) {
  html {
    font-size: 150%;
  }
}

/* Heading */
.heading1 {
  font-size: 2rem;
  text-align: center;
}

/* reference */
.reference {
  text-align: center;
}

/* reference link */
.reference-link {
  font-size: 1.25rem;
  font-weight: 600;
}

/* Carousel and animation css starts */
.rc-carousel {
  --box-gap: 1.5rem;
  --tile-size: 8rem;
  width: 100%;
  max-width: calc(
    (var(--tile-size) + var(--box-gap)) * ((var(--tiles) / 2) - 1)
  );
  margin: var(--box-gap) auto;
  overflow: hidden;
  position: relative;
  z-index: 10;
}
.rc-carousel::before,
.rc-carousel::after {
  content: "";
  position: absolute;
  top: 0;
  width: 5rem;
  height: 100%;
  /* background-image: -webkit-gradient(
    linear,
    ,
    from(transparent),
    to(var(--body-bg))
  ); */
  background-image: -o-linear-gradient(
    var(--direction),
    transparent,
    var(--body-bg)
  );
  background-image: linear-gradient(
    to var(--direction),
    transparent,
    var(--body-bg)
  );
  z-index: inherit;
}
.rc-carousel::before {
  left: 0;
  --direction: left;
}

.rc-carousel::after {
  right: 0;
  --direction: right;
}
.rc-carousel-strip {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  -webkit-animation: slideX 20s linear infinite;
  animation: slideX 20s linear infinite;
}
.rc-carousel-strip.reverse {
  /* reverse animation */
  animation-direction: reverse;
}
.rc-carousel-strip:hover {
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}
.rc-carousel-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  gap: var(--box-gap);
}

.rc-carousel-item {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: var(--tile-size);
  height: var(--tile-size);
}

.rc-carousel-item-image {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: center;
  object-position: center;
}

@-webkit-keyframes slideX {
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  0% {
    -webkit-transform: translateX(
      calc(-1 * (var(--tile-size) + var(--box-gap)) * (var(--tiles) / 2))
    );
    transform: translateX(
      calc(-1 * (var(--tile-size) + var(--box-gap)) * (var(--tiles) / 2))
    );
  }
}

@keyframes slideX {
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  0% {
    -webkit-transform: translateX(
      calc(-1 * (var(--tile-size) + var(--box-gap)) * (var(--tiles) / 2))
    );
    transform: translateX(
      calc(-1 * (var(--tile-size) + var(--box-gap)) * (var(--tiles) / 2))
    );
  }
}

/* Animation won't work if you preferes reduced motion */
@media (prefers-reduced-motion) {
  /* styles to apply if a user's device settings are set to reduced motion */
  .rc-carousel::before,
  .rc-carousel::after {
    display: none;
  }
  .rc-carousel-box {
    flex-wrap: wrap;
    justify-content: center;
  }
  .rc-carousel-box [aria-hidden="true"] {
    display: none;
  }
  .rc-carousel-strip {
    animation: none;
  }
}
/* reduced motion css ends */
/* Carousel and animation css ends */

/* Footer and credits styles */
/* .footer {
  position: fixed;
  z-index: 99;
  width: 100%;
  bottom: 0;
  left: 0;
  padding: 1rem;
  background: #fff;
  font-size: 0.75rem;
  text-align: right;
  font-weight: 600;
} */


/* 
.tab-container{
	margin: 5% 10%;
	background-color: #e3c1c1;
	padding: 3%;
	border-radius: 4px;
}

.tab-menu ul{
	margin: 0;
	padding: 0;
}

.tab-menu ul li{
	list-style-type: none;
	display: inline-block;
}

.tab-menu ul li a{
	text-decoration: none;
	color: rgba(0,0,0,0.4);
	background-color: #cbb4b4;
	padding: 7px 25px;
	border-radius: 4px;
}

.tab-menu ul li a.active-a{
	background-color: #fe0000;
	color: #ffffff;
}

.tab{
	display: none;
}

.tab h2{
	color: rgba(0,0,0,.7);
}

.tab p{
	color: rgba(0,0,0,0.6);
	text-align: justify;
}

.tab-active{
	display: block;
} */


.b-tab {
  padding: 20px;
  border: 1px solid #f50404;
  border-radius: 5px;
  display: none;
}

.b-tab.active {
  display: block;
}

.b-nav-tab {
  display: inline-block;
  padding: 10px;
  width: 100%;
  font-size: 25px;
  text-decoration: none;
  color: #000;
  margin-bottom: 15px;
}

.b-nav-tab.active {
  color: #ff4200;
  background-color: #efefef;
}

div ul li{
  list-style-type: none;
}

ol, ul{
  padding-left:0 !important;
}
.brand_sec{
  background-color: #000;
}